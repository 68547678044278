/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
// .auth-full-bg .right-sec a{
//   color:#004745!important;
// }



h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

body {
  padding: 0px !important;
  font-size: 14px !important;
  margin: 0px !important;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

input[type="checkbox"],
input[type="radio"] {
  background: #dadce5;
  border: 1px solid #b1afaf;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked+label {
  background-color: #004745 !important;
  border: 1px solid #004745 !important;
  color: #fff;
  padding: 0.25rem;
}

.form-control[type="tel"],
[type="url"],
[type="password"],
[type="email"],
[type="number"],
[type="text"],
select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #000 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  padding: 0.5rem 1rem !important;
  border-radius: 32px !important;
  border: 1px solid #dadce5 !important;
}

select:disabled {
  background-color: #ccc !important;
}

select.custom-slct {
  float: right;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.9rem !important;
  width: auto;
  max-width: 185px;
  margin-bottom: 1rem;
  border-radius: 5px !important;
}

.pwd-con {
  position: relative;

  .pwd-eye {
    position: absolute;
    top: 0;
    right: 20px;
    padding: 0.77rem;
    z-index: 999;
  }
}

.content-con {
  margin: 2rem 0;

  h4 {
    font-size: 1.1rem;
    margin-top: -6px;
    margin-bottom: 17px;
  }
}

.content-con-1 {
  margin: 1rem 0;

  h4 {
    font-size: 1.1rem;
    margin-top: -6px;
    margin-bottom: 17px;
  }
}

.mr-1 {
  margin-right: 1rem;
}

.page-content {
  min-height: 100vh;
}

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.form-control[type="tel"],
[type="url"],
[type="password"],
[type="email"],
[type="number"],
[type="text"], 
[type="date"]{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #000 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  padding: 0.5rem 1rem !important;
  border-radius: 32px !important;
  border: 1px solid #004745 !important;
}

// .form-control[type="tel"],
// [type="url"],
// [type="password"],
// [type="email"],
// [type="number"],
// [type="text"], 
// [type="date"]:disabled {
//  background-color: #EBEBE4 !important;
//  border: none;
// }

app-information-details .card,
app-information-service .card,
app-information-providers .card,
app-information-speciality .card,
app-information-enrollments .card,
app-information-order .card,
#settings .card {
  background: none;
  box-shadow: none;
  margin-bottom: 0;
}

app-information-details .card h2.card-title,
app-information-service .card h2.card-title,
app-information-providers .card h2.card-title,
app-information-speciality .card h2.card-title,
app-information-enrollments .card h2.card-title,
app-information-order .card h2.card-title,
#settings .card h2.card-title,
.page-title-left h2 {
  font-size: 1.8rem;
  color: #004745;
  font-weight: bolder;
}

app-information-details .card h4.card-sub-title,
app-information-service .card h4.card-sub-title,
app-information-providers .card h4.card-sub-title,
app-information-speciality .card h4.card-sub-title,
app-information-enrollments .card h4.card-sub-title,
app-information-order .card h4.card-sub-title,
#settings .card h4.card-sub-title,
.page-title-left h4 {
  color: #858796 !important;
  font-weight: 600;
  font-size: 1rem;
}

.steps ul {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0rem;
  list-style: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
}

.steps ul li {
  width: auto;
  padding: 0 0.25rem;
  height: 50px;
  white-space: nowrap;
}

.steps ul li.current a {
  color: #004745;
}

.wizard .steps .number {
  border: 1px solid #f1f1f1 !important;
  background: #f1f1f1;
  color: #b1afaf !important;
  line-height: 38px !important;
}

.wizard .steps .current a .number,
.wizard .steps .current a:active .number,
.wizard .steps .current a:hover .number {
  background-color: #c12228 !important;
  border: none;
  color: #fff !important;
}

.wizard .steps .done a .number,
.wizard .steps .done a:active .number,
.wizard .steps .done a:hover .number {
  background-color: #004745 !important;
  border: none;
  color: #fff !important;
}

.steps .current a,
.steps .current a:active,
.steps .current a:hover {
  color: #004745 !important;
}

.steps ul li a {
  padding: 0.25rem !important;
  background: none !important;
  display: flex !important;
  flex-direction: row;
}

.steps ul li a p b {
  display: block;
}

//Button Styles
a.btn-xl,
button.btn-xl {
  width: 100%;
  font-size: large;
}

a.custom-btn,
button.custom-btn {
  border: 1px solid #004745 !important;
  background: #004745 !important;
  color: #fff !important;
  border-radius: 30px !important;
  padding: 0.5rem 3rem !important;
}

a.custom-btn_red,
button.custom-btn_red {
  border: 1px solid #c12228 !important;
  background: #c12228 !important;
  color: #fff !important;
  border-radius: 30px !important;
  padding: 0.5rem 3rem !important;
}

a.custom-btn:hover,
button.custom-btn:hover {
  background: #004745 !important;
  color: #fff !important;
  border: 1px solid #004745 !important;
}

.select-service {
  display: flex;
  justify-content: center;
}

.custom-select-item {
  padding: 1rem 1rem 0.5rem;
  margin: 0.5rem;
}

.custom-select-item button {
  border: 1px solid #dadce5;
  background: #fff;
  padding: 1rem 2rem;
  box-sizing: border-box;
  margin: 1rem 0rem;
  display: block;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  color: #004745;
  width: 100%;
  box-shadow: 0 8px 8px -2px #cacaca;
}

.custom-select-item button:hover {
  background: #004745;
  color: #fff;
}

.custom-select-item button i {
  margin-right: 3px;
}

a.custom-link {
  position: absolute;
  top: -14px;
  right: 0;
  border: 1px solid #000;
  padding: 0.35rem 1rem;
  border-radius: 50px;
  color: #004745;
  text-decoration: none;

  &:hover {
    background-color: #004745;
    color: #fff;
  }
}

button.custom-link {
  border: 1px solid #000;
  padding: 0.35rem 1rem;
  border-radius: 50px;
  color: #004745;
  text-decoration: none;

  &:hover {
    background-color: #004745;
    color: #fff;
  }
}

a.custom-link1 {
  position: absolute;
  top: -14px;
  right: 0;
  border: 1px solid #000;
  padding: 0.35rem 1rem;
  border-radius: 50px;
  color: #fff;
  text-decoration: none;
  background-color: #004745;

  &:hover {
    background-color: #fff;
    color: #004745;
  }
}

button.custom-btn-1,
a.custom-btn-1 {
  border: 1px solid #004745;
  background: #fff;
  border-radius: 30px;
  padding: 0.5rem 3rem;
  color: #004745;

  &:hover {
    background: #fff;
    border: 1px solid #004745;
    color: #004745;
  }
}

button.custom-btn-3,
a.custom-btn-3 {
  border: 1px solid #004745;
  background: #004745;
  border-radius: 30px;
  padding: 0.5rem 3rem;
  color: #fff;

  &:hover {
    background: #fff;
    border: 1px solid #004745;
    color: #004745;
  }
}

button.custom-btn-2,
a.custom-btn-2 {
  border: 1px solid #c12228;
  background: #c12228;
  border-radius: 30px;
  padding: 0.5rem 2rem;
  color: #fff;

  &:hover {
    background: #004745;
    border: 1px solid #004745;
    color: #fff;
  }
}

button.custom-btn-sml,
a.custom-btn-sml {
  border: 1px solid #dadce5;
  background: #f3eefa;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #004745;
  border-radius: 30px;
  padding: 0.35rem 1rem;
  margin-left: 0.5rem;

  &:hover {
    background: #004745;
    color: #fff;
    border: 1px solid #fff;
  }
}

.bg-whitebg-2 {
  border-radius: 5rem;
  background-color: #fff;
}

.bg-whitebg {
  border-radius: 1rem;
  background-color: #fff;
}

.add-provider {
  border-radius: 2rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
}

//Data-Tables styles

table.datatables a i {
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1rem;
  color: #004745;
}

table.datatables a i.custom {
  font-size: 2rem;
}

table.datatables {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
  border: none;
}

table.datatables td,
table.datatables th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

table.datatables th {
  background: #004745 !important;
  color: #fff;
  vertical-align: middle;
}

table.datatables td {
  background: #fff;
  border: none;
}

table th:first-child,
table td:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  //text-align:center;
  width: 10%;
}

table th:last-child,
table td:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  //padding-right:5%;
  //text-align:right;
  white-space: nowrap;
}

.table-lightbg th {
  background: #f5f5f5 !important;
}

table th.nh {
  color: #00a6a1;
  font-weight: 300;
  line-height: 17px;
  font-size: 0.7rem;
}

table th.nh span {
  font-weight: 300;
  color: #00a6a1;
  padding-right: 0.1rem;
  float: left;
  font-size: 2.4rem;
  line-height: 2.4rem;
}

.success-chkdone {
  color: #44d600;
}

// Accordion card styles - speciality and enrollment page styles

// .accordion .card-header button{
//   padding:0.75rem 1.25rem;
//   text-decoration: none;
// }

// .accordion .card-header button span{
//  padding-right:80px;
// }

// .accordion .card-header button .form-check-input{
//   margin-right:0.5rem;
// }

.accordion .card {
  border: 1px solid #fff !important;
  border-bottom: 2px solid #edeff4 !important;
}

.card .card-header {
  padding: 1rem;
  cursor: pointer;
  border-radius: 0.5rem !important;
  background: #004745;
  color: #ffffff;
}

.accordion .card-header i.bx {
  float: right;
  font-size: 1.4rem;
}

.accordion .card-header h5 {
  padding-right: 0.5rem;
}

.accordion .card-body {
  background: #edeff4;
}

.card .expanded .card-header {
  background: #ffffff !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  text-decoration: none;
  font-weight: 700;
}

.accordion table.table-services {
  border: none;
  max-width: 100%;
  width: 100%;
}

table.table-services th,
table.table-services td {
  background: #fff;
  padding: 0.75rem 0.25rem 0.75rem 1rem;
  vertical-align: middle;
  font-size: 1rem;
  border-bottom: 0px solid #e3e6f0;
}

table.table-services th.order-hdng {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 500;

  &:first-child {
    width: 35%;
  }

  &:last-child {
    width: 65%;
  }
}

table.table-services td.order-cnt {
  font-size: 0.8rem;
  line-height: 1.5rem;

  &:first-child {
    border-right: 1px solid #cacaca;
    vertical-align: top;
  }
}

table.table-services th:first-child,
table.table-services td:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

table.table-services th:last-child,
table.table-services td:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

table.table-services th {
  text-align: center;
  border-bottom: 2px solid #edeff4 !important;
  padding: 15px;
}

table.table-services td ul {
  list-style: none;
  padding: 0.5rem 0rem;
}

table.table-services td ul::-webkit-scrollbar {
  width: 0.15em;
}

table.table-services td ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

table.table-services td ul::-webkit-scrollbar-thumb {
  background-color: #004745;
  outline: 0.5px solid #004745;
  border-radius: 10px;
}

table.table-services td ul li {
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  font-size: 0.8rem;
  white-space: break-spaces;

  span {
    label {
      max-width: 95%;
    }
  }
}

table.table-services td ul li.tl {
  border-top: 1px solid #edeff4;
}

table.table-services td ul li button,
table.table-services td ul li input[type="checkbox"] {
  float: right;
}

table.selected-services1 td {
  background-color: #fff;
}

table.selected-services h4,
table.selected-services1 h4 {
  margin-bottom: 0;
}

table.selected-services h5,
table.selected-services1 h5 {
  border-bottom: 1px solid #cacaca;
  padding-bottom: 0.5rem;
  font-size: medium;
}

table.selected-services h5 i,
table.selected-services1 h5 i {
  cursor: pointer;
  float: right;
  font-size: 1.2rem;
}

table.selected-services ul,
table.selected-services1 ul {
  width: 100%;
  float: left;
  padding: 0.5rem 0 0 0;
  margin-left: 0;
}

table.selected-services ul li {
  float: left;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 0.5rem 0.35rem 0rem !important;
  list-style: none;
  margin: 0 0.25rem 0.25rem 0.25rem;
  text-align: justify;
  min-width: 75px;

  span {
    text-align: left;
    font-size: 0.9rem;
    padding-left: 0.5rem;
  }

  i {
    font-weight: bold;
    float: right;
    margin-top: 0.15rem;
    font-size: 1rem;
  }
}

table.selected-services1 ul li {
  float: left;
  width: 100%;
  padding: 0.15rem 0.45rem;
  list-style: none;
  margin: 0.25rem;
  margin-left: 0;
  font-size: 0.8rem;
  font-weight: 500;
  color: #000;
  display: flex;
  align-items: center;
}

table.selected-services1 ul li i {
  cursor: pointer;
  font-size: 1rem;
  // padding-top: 0.5rem;
  font-weight: bold;
  margin-right: 0.5rem;
}

ul.select-service {
  width: 100%;
  display: flex;
  list-style: none;
  justify-content: space-between;
}

ul.select-service li {
  width: 48%;
  border-radius: 10px; //32px
  background-color: #fff;
  padding: 1rem 0.75rem 0.5rem;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.mini-stats-wid .card-body {
  padding: 0.5rem;
}

.mini-stats-wid .card-body {
  white-space: nowrap;
  font-size: 0.7rem;
}

.table-speciality {
  tr {
    td {
      p {
        font-size: 0.8rem;
        white-space: word-break;

        span {
          float: right;
        }
      }
    }
  }
}

//Nav-tabs- CSS overwriting

.nav-tabs {
  border-bottom: none !important;
}

.nav-tabs .nav-link {
  background: #fff !important;
  border: 1px solid #fff !important;
  color: #000 !important;

  &:hover {
    color: #000;
  }

  span {
    b {
      font-weight: bolder;
      color: #e6e6e6;
      padding-left: 1.5rem;
      float: right;
      font-size: 1.4rem;
      margin-top: -5px;
    }
  }
}

.nav-tabs .nav-link.active {
  background: #004745 !important;
  border: 1px solid #004745 !important;
  border-bottom: none !important;
  color: #fff !important;
}

.list-autoscroll {
  min-height: 300px;
  padding-left: 0;
  //  overflow-y: scroll;
  //  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  //  scrollbar-width: none; /* for Firefox */
  //  overflow-y: scroll;
  border-right: 2px solid #dadce4;
  border-radius: 2px;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>.active i,
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>.active:hover i {
  background: #004745 !important;
  color: #fff !important;
  border-radius: 3px;
}

h2.pg-hdng {
  font-weight: bolder;
  color: #004745;
  margin-bottom: 2.4rem;
}

table.lite-brdr {
  td {
    border: 1px solid #dadce5;
  }
}

.required-star {
  color: crimson;
}

.errors {
  color: crimson;
  padding: 5px 0 0 10px;
  font-weight: 600;
  margin-left: 7px;
  margin-top: 5px;
  font-size: 11px !important;
}

// PURNIMA CODE START
.DM_list {
  margin: 10px 40px;
}

// PURNIMA CODE END


/////////////////////// New Modified Code Starts on Sep2nd 2023 //////////////////

// cursor pointer
.pointer {
  cursor: pointer !important;
}

input:disabled {
  background: #ccc !important;
}

////// font styles ////////////
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font_26{
  font-size: 26px !important;
}
.font-weight-500{
  font-weight: 500;
}

.font-24 {
  font-size: 24px;
}

.fw-600 {
  font-weight: 600;
}

.f_1rem {
  font-size: 1rem;
}

.font-18 {
  font-size: 18px;
}
.font_1rem{
  font-size: 1rem;
}
.font-large{
  font-size: large !important;
}

////////////////////////////////
.selected_service_bar {
  margin-top: 32px !important;
}

//Width styes 

.W-10 {
  width: 10%;
}
.W-12 {
  width: 12%;
}

.W-15 {
  width: 15% !important;
}

.W-20 {
  width: 20% !important;
}

.W-25 {
  width: 25% !important;
}

.W-30 {
  width: 30% !important;
}

.W-32 {
  width: 32% !important;
}
.W-50{
  width: 50% !important;
}

.W-40{
  width: 40% !important;
}
.W-90{
  width: 90% !important;
}

.W-5 {
  width: 5% !important;
}

//Width styes Ends
// sub text 
.subText {
  color: #858796 !important;
  font-weight: 600;
  font-size: 1.2rem;
}

.cancel_icon {
  color: #c12228 !important;
  font-size: 1.2rem !important;

}

.save_icon {
  font-size: 1.2rem !important;

}

.edit_icon {
  font-size: 1.2em !important;
}

.edit_disable_icon {
  font-size: 1.4rem !important;
  color: grey !important;
}

// sub text 
.subText {
  color: #858796 !important;
  font-weight: 600;
  font-size: 0.9rem;
}

.cursor_Pointer {
  cursor: pointer;
}

/// Margin styles
.mb-4{
  margin-bottom: 4px !important;
}
.m-b-10{
  margin-bottom: -10px !important;
}
.m-top-5 {
  margin-top: -5px !important;
}
.m-top-6 {
  margin-top: -6px !important;
}

.m-top-7 {
  margin-top: -7px !important;
}

.m-top-8 {
  margin-top: -8px !important;
}

.m-top-10 {
  margin-top: -10px !important;
}

.m-top-15 {
  margin-top: -15px !important;
}
.m-top-40 {
  margin-top: -40px !important;
}

.m-t-5{
  margin-top: 5px !important;
}
.m-t-6{
  margin-top: 6px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}

.m-r-4p {
  margin-right: 4%;
}
.m-r-10{
  margin-right: 10px !important;
}

.m-r-3p {
  margin-right: 3%;
}
.m-l-5{
  margin-left: 5px !important;
}
.ml-5{
  margin-left: -5px !important;
}
.m-l-6{
  margin-left: 6px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.ml-80{
  margin-left: -80px !important;
}
.m-l-12 {
  margin-left: 12px !important;
}
.m-l-15{
  margin-left: 15px !important;
}
.m-l-0{
  margin-left: 0px !important;
}
.ml-10{
  margin-left: -10px !important;
}
.ml-15{
  margin-left: -15px !important;
}
.m-l-40{
  margin-left: 40px !important;
}

.m-l-18 {
  margin-left: 18px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.m-bottom-30{
  margin-bottom: -30px !important;
}

.ml_6 {
  margin-left: 6px;
}

/// Margins styles

///// Styles Mixed for all components//////

.btn_custom_save:hover {
  background: #004745 !important;
  color: #fff !important;
  border: 1px solid #fff;
  padding: 8px 25px;
  border-radius: 20px;
}

.btn_custom_save {
  background: #f3eefa !important;
  color: black !important;
  border: 1px solid #f3eefa !important;
  padding: 8px 25px !important;
  border-radius: 20px !important;

}

.error_icons {
  vertical-align: middle;
  line-height: 1;
  font-size: 1rem;
}

.f-1_2rem {
  font-size: 1rem;
}

.text_success {
  color: green;
  font-weight: 600;
  margin-left: 7px;
  margin-top: 5px;
}

.text_errors {
  color: crimson;
  font-weight: 600;
  margin-left: 7px;
  margin-top: 5px;
}

.invalid-feedback {
  margin-left: 8px;
}

.list_none {
  list-style-type: none !important;
}

.orders_cards {
  margin-bottom: -24px !important;
  border: none !important;
}

.eye_icon_styles {
  color: #004745;
  margin-right: 10px;
}

.copy_rights_styles {
  margin-left: 40px;
  color: #004745;
}

._color{
  color: #004745 !important;
}
._redcolor{
  color: #c12228 !important;
}
.bg_standard_color{
  background-color: #004745 !important;
}
.white_color{
  color: #fff !important;
}

.bg_color_white{
  background-color: #fff !important;
}
//////////////Global styles across dashboard//////////////////
// label {
//   color: #004745 !important;
//   font-size: 16px !important;
//   font-weight: 700 !important;
// }

._disable_color{
  color: #74789c !important;
}
.float-right{
  float: right !important;
}
.vertical-middle{
  vertical-align: middle !important;
}
.vertical_sub{
  vertical-align: sub !important;
}

::ng-deep .popover{
  background-color: #e7f0f0 !important;
  --bs-popover-bg: #e7f0f0 !important;
}
::ng-deep .popover-header{
  background-color: #004745 !important;
  color: #fff;
}
::ng-deep .popover-body{
  color: #004745 !important;
}
.btn_popover_noborder{
  border:  none !important;
}
._info_color_icon{
  color: #c12228 !important;
}
._info_color_icon:hover{
  color: #004745 !important;
}
.practice_logo {
  color: white;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
  vertical-align: middle;
  padding: 2px;
  border: 1px solid #004745;
}
.user_name_logo {
  color: white;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
  vertical-align: middle;
}
.practice_letter_logo{
  color: white;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
  vertical-align: middle;
}
.user_letters{
  margin-left: 11px;
  margin-top: 16px;
  letter-spacing: 1px;
  padding-left: 5px;
}

.required {
 color: crimson;
}


////////Browser Compatability 
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.text_capitalize{
  text-transform: capitalize !important;
}
.cursor_pointer{
  cursor:  pointer !important;
}

.defaultGreed {
  color: #004745 !important;
}

.modal-content  {
  border: none !important;
}

.ngb-dp-navigation-select .form-select {
  padding: 0 0.5rem !important;
  padding-top: 0px !important;
  padding-right: 0.5rem !important;
  padding-bottom: 0px !important;
  padding-left: 0.5rem !important;
  font-size: .875rem !important;
  border-radius: 5px !important
}

.width-increase {
  width: 40% !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.pac-container {
  z-index: 1200 !important;
}

.multiSelect {
  position: relative !important;
  top: -10px;
}

.mutiSelectContainer {
  display: inline-block;
  top: 3px;
  border: none !important;
  width: 100%;
}



.btnDisable {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #000 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  padding: 0.5rem 1rem !important;
  border-radius: 32px !important;
  border: 1px solid #dadce5 !important;
}

.btnDisable:disabled {
  background-color: #ccc !important
}

.dropdown-item {
  padding: 4px 2px 4px 2px !important;
}

.header-design {
  background: #004745 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  text-decoration: none;
  font-weight: 700;
  padding: 1.1rem;
}

.custom-popover-class {
  /* Add your custom styles here */
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  color: #333;
}

.custom-ng-select .ng-input >input {
  border: none !important;
  border-radius:0px !important;
  padding:0px !important;
  background-color: transparent !important;
  width: 300px !important;
  padding-left: 10px !important;
  }

  .ng-select-container {
    border-radius: 36px !important;
  }

  .ng-value-label {
    padding-left: 10px !important;
  }

  .txt-wrap {
    text-wrap: wrap !important;
    line-height: 20px !important;
  }

