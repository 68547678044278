// 
// _nav.scss
// 

.nav-tabs {
  > li {
      margin-right:0.5rem;
      > a {
          color: var(--#{$prefix}gray-700);
          font-weight: $fw-medium;
          background-color: #f5f5f5;
      }
  }
}

.nav-pills {
  > li {   
      > a {
          background:#EDEFF4!important;
          border-radius:1rem!important;
          color: var(--#{$prefix}gray-700);
          font-weight: $fw-medium;
          &:hover{
            color:#004745;
          }
      }
  }
}

.nav-pills .nav-link.active{
  background:#004745!important;
  color:#EDEFF4!important;
  
}

.nav-pills {
  > a {
      color: var(--#{$prefix}gray-700);  
      font-weight: $fw-medium;
  }
}


.nav-tabs-custom {
  border-bottom: 2px solid var(--#{$prefix}gray-300);
  .nav-item {
    position: relative;
    margin-right:0rem;
    color: var(--#{$prefix}dark);
    .nav-link {
      border: none!important;
      background:none!important;
      &::after {
        content: "";
        background: #DF0000;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }
      &.active{
        color: $primary;      
        &:after{
          transform: scale(1);
        }
      }    

    }
  }
}

// .nav-tabs-custom{
//  .nav-item{
//   .nav-link{
//   }
//  }
// }



// vertical nav

.vertical-nav{
  .nav{
      .nav-link{
          padding: 24px 16px;
          text-align: center;
          margin-bottom: 8px;
          .nav-icon{
              font-size: 24px;
          }
      }
  }
}