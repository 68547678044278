html{
    background:white;
    overflow-x:hidden;
 } 
// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

// auth 2

.auth-logo{
    .auth-logo-dark{
        display: $display-block;
    }
    .auth-logo-light{
        display: $display-none;
    }
}

.auth-body-bg{
    background-color: $card-bg;
}


// auth-pass-inputgroup

.auth-pass-inputgroup{
    input[type="input"] + .btn .mdi-eye-outline{
        &:before{
            content: "\F06D1";
        }
    }
}

// authentication full page

.auth-full-bg{
    width:100vw; 
    background: url(../../../images/account/reg-bg.jpg) no-repeat left top #E7F0F0;
    background-size: contain;
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px){
        height: 100vh;
    }

    .header{
        height:100px;
        width:100%;
        display: flex;
        .logo{
            background: url(../../../images/logo.png) no-repeat left top;
            background-size: contain;
            width:160px;
            height:56px;
            margin:35px 0px 0px 35px;
            text-indent: -9999px;
        }
    }

    .left-sec-img{
        background: url(../../../images/account/reg-bg1.png) no-repeat 51px bottom;
        background-size: 45%;
            @media only screen and (max-width:767.98px)  {
            background: none;
            }
            @media only screen and (max-width:991.98px) and  (min-width:767.99px) {
            background: url(../../../images/account/reg-bg1.png) no-repeat right 23%!important;
            background-size: 23%!important;
            min-height: 245px;      
            }            
        }

        h3.main-title{
            width: 65%;
            font-weight:700;
            text-align: center;
            margin: 0 0 0 60px;
            @media (max-width: 991.98px) {
                // width: 62%;
                // margin: 0px 0px 0px 38%;
                width:65%;
                margin:0% 10%;
                font-size: 1.1rem;
            }
        }

        .right-sec{ 
            @media (max-width: 991.98px) {
                margin-top:2rem;
                margin-bottom:2rem;
            }  

            ul.steps-indicator li .step-indicator{
                border: 1px solid #A6ACBE!important;
                background: #fff;
                color: #fff!important;
                line-height:55px!important;
                font-size:36px;
            }

            ul.steps-indicator li.current .step-indicator {
                border: 1px solid #A6ACBE!important;
                color: red!important;
                background:#fff;
            }

            ul.steps-indicator li.done .step-indicator {
                border: 1px solid #A6ACBE!important;
                color: #339933!important;
                background: #fff;
            }


            
            span.inputlink {                
                float:left;
                width:100%;
                margin:.5rem 0rem;                
                }   
                h3{
                    width: 63%;
                    font-weight:700;
                    text-align: left;
                    line-height: 40px;               
                    }
                h4{
                    text-align:center;
                    font-weight:700;
                    line-height: 40px;  
                }
                h5{
                    text-align:center;
                    font-weight:500;
                    line-height: auto;  
                }
                

                form{
                    width:80%;
                    @media (max-width: 991.98px) {
                        width:99%;
                        }
                    .form-group{
                        margin-bottom:1rem;
                    }
                    label{
                        margin-bottom:0.5rem;
                    }
                    label.form-check-label{
                        color:#828282;
                    }
                    input, select{                  
                        padding:0.5rem 1rem;  
                        border-radius:32px;
                        border:1px solid #000;
                    }
                    select.form-control{
                        -webkit-appearance: menulist!important;
                        -moz-appearance: menulist!important;
                        -ms-appearance: menulist!important;
                        -o-appearance: menulist!important;
                        appearance: menulist!important;
                    }
                    input[type=checkbox]{
                        border-radius:2px;
                        border:1px solid #E0E0E0;
                        padding:0;
                    }
                    a{
                        color:#004745;
                        text-decoration:underline;                       
                        &:hover{
                         color:crimson;
                        } 
                    }
                    a.forgot{
                        color:#828282;
                        text-decoration:none;
                        float:right;
                        &:hover{
                         color:#004745;
                        }                     
                    }
                    button.custom-btn{
                        border:1px solid #004745;
                        background:#004745;
                        color:#fff;
                        border-radius:30px;
                        padding:.5rem 3rem;
                        &:hover{
                        background:#004745;
                        color:#fff;
                        }
                    }
                    button.custom-btn-1{
                        border:1px solid #004745;
                        background:#fff;
                        border-radius:30px;
                        padding:.5rem 3rem;
                        // &:hover{
                        // background: #fff;
                        // border:1px solid #c12228;
                        // color:#c12228;
                        // }
                    }
                    .btn-right{
                        float:right;
                    }
                }
                
            }




    &::before{
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 50%;
    }

    .bg-overlay{        
        background: url(../../../images/account/reg-bg.jpg) no-repeat left top #E7F0F0;
        background-size: contain;
    }
}



.auth-full-page-content{
    display: flex;

    @media (min-width: 1200px){
        min-height: 100vh;
    }

  
}

.auth-review-carousel{
    &.owl-theme {
        .owl-dots {
            .owl-dot{
                span{
                    background-color: rgba($primary, 0.25);
                }
                &.active, &:hover{
                    span{
                        background-color: $primary;
                    }
                }
            }
        }
    }
}


